<template>
  <transition name="fade" v-if="show">
    <section class="screen-modal slider-modal">
      <div class="modal-backdrop"></div>
      <div class="modal-content-wrapper">
        <div
          @click="setActiveSlide(-1)"
          :class="{ 'visibility-hidden': activeSlide === 0 }"
          class="modal-slider-arrow modal-slider-arrow-left rotate-1"
        >
          <icon icon="#cx-app1-arrow-right-12x12" width="14" height="20" />
        </div>
        <div class="modal-content">
          <header v-if="icon" class="modal-header">
            <svg-icon :icon="icon"></svg-icon>
          </header>
          <section
            v-if="data && data.length && data[activeSlide]"
            class="modal-inner-content"
          >
            <slot :data="data[activeSlide]"></slot>
          </section>
          <footer class="modal-footer">
            <button
              v-if="showCancelButton"
              class="modal-btn"
              @click="closeModal"
            >
              {{ cancelButtonLabel }}
            </button>
            <button class="modal-btn" @click="confirm">
              {{ confirmButtonLabel }}
            </button>
          </footer>
          <div v-if="showNavigationNumbers" class="modal-slider-number">
            {{ activeSlide + 1 }}/{{ data.length }}
          </div>
        </div>
        <div
          @click="setActiveSlide(1)"
          :class="{ 'visibility-hidden': activeSlide + 1 === data.length }"
          class="modal-slider-arrow modal-slider-arrow-right"
        >
          <icon icon="#cx-app1-arrow-right-12x12" width="14" height="20" />
        </div>
      </div>
    </section>
  </transition>
</template>

<script>
export default {
  name: "SliderModal",
  methods: {
    closeModal() {
      if (this.cancelAction) {
        this.cancelAction();
      } else {
        this.$emit("close");
      }
    },
    confirm() {
      if (this.confirmAction) {
        this.confirmAction();
      } else {
        this.$emit("close");
      }
    },
    setActiveSlide(direction) {
      let newActiveSlide = this.activeSlide + direction;
      if (newActiveSlide < 0 || newActiveSlide > this.data.length - 1) {
        return;
      }
      this.activeSlide = newActiveSlide;
    }
  },
  data() {
    return {
      activeSlide: 0
    };
  },
  props: {
    data: {
      required: true,
      type: Array
    },
    confirmAction: {
      required: false,
      type: Function,
      default: null
    },
    show: {
      required: false,
      type: Boolean
    },
    confirmButtonLabel: {
      required: false,
      type: String,
      default: "OK"
    },
    cancelButtonLabel: {
      required: false,
      type: String,
      default: "Cancel"
    },
    icon: {
      required: false,
      type: String,
      default: ""
    },
    showCancelButton: {
      required: false,
      type: Boolean
    },
    cancelAction: {
      required: false,
      type: Function,
      default: null
    },
    showNavigationNumbers: {
      required: false,
      type: Boolean,
      default: true
    }
  }
};
</script>
